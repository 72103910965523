@font-face {
  font-family: Microsoft;
  src: url('./assets/font/ms-sans-serif.ttf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Inconsolata";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  touch-action: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  :root {
    font-size: 14px;
  }
}

@media screen and (min-width: 2560px) {
  :root {
    font-size: 18px;
  }
}
